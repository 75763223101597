import { ButtonProps, Tooltip } from '@chakra-ui/react';
import IconVote from './icons/IconVote';
import { useState } from 'react';
import IconVoteHover from './icons/IconVoteHover';
import Button from '../button';

const VoteButtonStyles = {
  bg: 'gray.200',
  color: 'gray.900',
  _hover: {
    bg: '#064AC4',
    border: '1px solid',
    borderColor: 'gray.300',
    color: '#FFFFFF',
  },
  _disabled: {
    bg: '#FFFFFF',
    border: '1px solid',
    borderColor: 'gray.300',
    _hover: {
      color: 'gray.900',
      cursor: 'not-allowed',
      opacity: 1,
    },
  },
};

interface VoteButtonProps extends ButtonProps {
  hasVotingEnded?: boolean;
  hasVoted?: boolean;
}

const VoteButton = (props: VoteButtonProps) => {
  const [isHover, setIsHover] = useState(false);

  const { hasVotingEnded, hasVoted, ...rest } = props;

  const isDisabled = hasVotingEnded || hasVoted;

  return (
    <Tooltip
      label={
        isDisabled
          ? hasVotingEnded
            ? 'There is not a currently active contest'
            : "You've already voted in this contest"
          : null
      }
      placement="top"
    >
      <Button
        isDisabled={isDisabled}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        {...VoteButtonStyles}
        {...rest}
      >
        {isDisabled ? null : isHover ? <IconVoteHover mr="8px" /> : <IconVote mr="8px" />}
        Vote
      </Button>
    </Tooltip>
  );
};

export default VoteButton;
