export const textStyles = {
  subtitle: {
    fontSize: '18px',
  },
  button: {
    fontSize: 'md',
    fontWeight: 600,
  },
  body: {
    fontSize: { base: '14px', md: '16px' },
    lineHeight: { base: '18px', md: '22px' },
  },
  bodySmall: {
    fontSize: { base: '13px', md: '14px' },
    lineHeight: '18px',
  },
  bodySuperSmall: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  caption: {
    fontSize: 'sm',
  },
  overline: {
    fontSize: '13px',
    lineHeight: '18px',
  },
  breadcrumb: {
    cursor: 'pointer',
    fontSize: { base: '12px', md: '13px' },
    fontWeight: { base: 500, md: 600 },
  },
};
