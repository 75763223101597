import { useEffect, useState } from 'react';
import { Center, Hide, Show, Stack, useBreakpointValue } from '@chakra-ui/react';

import DesignPreview from './DesignSideImage';
import { Design } from '@/lib/types';

interface DesignPreviewGalleryProps {
  design: Design;
}

const DesignPreviewGallery = ({ design }: DesignPreviewGalleryProps) => {
  const [selectedSide, setSelectedSide] = useState<string>(null);

  const { template } = design;

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (!template) {
      return;
    }
    setSelectedSide(template?.sides[0]?.name);
  }, [template]);

  const thumnbailImageWidth = isMobile ? 82 : 86;

  return (
    <Stack
      align="flex-start"
      bg="#FFFFFF"
      borderRadius="10px"
      direction={{ base: 'column', md: 'row' }}
      justify={{ base: 'space-between', md: 'flex-start' }}
      h="100%"
      spacing="5px"
      w={{ base: '100%', md: 'auto' }}
    >
      <Hide above="md">
        <DesignPreview
          design={design}
          sideName={selectedSide}
          imageWidth={265}
          height="auto"
          width="100%"
        />
      </Hide>
      {template.sides.length > 1 ? (
        <Stack direction={{ base: 'row', md: 'column' }} spacing={{ base: '5px', md: '8px' }}>
          {template.sides.map((side) => (
            <Center
              as="button"
              borderRadius={4}
              borderColor="secondaryDarkGray.600"
              borderWidth={selectedSide === side.name ? '1px' : 0}
              key={side.name}
              onClick={() => setSelectedSide(side.name)}
            >
              <DesignPreview
                design={design}
                sideName={side.name}
                imageWidth={64}
                isClickable
                height={isMobile ? 82 : 86}
                width={thumnbailImageWidth}
              />
            </Center>
          ))}
        </Stack>
      ) : null}
      <Show above="md">
        <DesignPreview
          design={design}
          sideName={selectedSide}
          imageWidth={520}
          height={529}
          width={540}
        />
      </Show>
    </Stack>
  );
};

export default DesignPreviewGallery;
