import IconLinkArrowRight from '@/lib/components/icons/IconLinkArrowRight';
import { Box, HStack, Link, Text } from '@chakra-ui/react';
import { useState } from 'react';

interface RoyaltiesBannerProps {
  onViewIps: () => void;
}

const RoyaltiesBanner = ({ onViewIps }: RoyaltiesBannerProps) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <Box
      bg="green.300"
      borderLeft="2px solid"
      borderLeftColor="green.600"
      p="10px 28px 12px 16px"
      w="100%"
    >
      <Text mb="6px" textStyle="body">
        You earn $15 royalties from every single sale!
      </Text>
      <Link
        onClick={onViewIps}
        _hover={{ textDecoration: 'none' }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <HStack spacing="2px">
          <Text color={isHovered ? 'green.900' : 'green.600'} fontWeight={600} textStyle="body">
            My IPs
          </Text>
          <IconLinkArrowRight color={isHovered && '#136400'} />
        </HStack>
      </Link>
    </Box>
  );
};

export default RoyaltiesBanner;
