import { useEffect, useState } from 'react';
import { Box, Center, HStack, IconButton, Text, useBreakpointValue } from '@chakra-ui/react';

import { Design } from '@/lib/types';
import SubmitDesignSideImage from './SubmitDesignSideImage';
import IconArrowRight from '@/lib/components/icons/IconArrowRight';
import { size } from 'lodash';

const THUMBNAIL_IMAGE_WIDTH = 82;

const ARROW_SIZE = 36;

interface DesignPreviewGalleryProps {
  design: Design;
}

const SubmitDesignPreviewGallery = ({ design }: DesignPreviewGalleryProps) => {
  const [selectedSideIndex, setSelectedSideIndex] = useState<number>(0);

  const [hoveredSide, setHoveredSide] = useState<string>(null);

  const { template } = design;

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (!template) {
      return;
    }
    setSelectedSideIndex(0);
  }, [template]);

  return (
    <Center
      bg="secondaryGray.300"
      flexDirection="column"
      h="100%"
      position="relative"
      overflow="auto"
      w="100%"
    >
      <Box position="relative" w="60%">
        <SubmitDesignSideImage
          design={design}
          sideName={template.sides[selectedSideIndex].name}
          imageWidth="100%"
          width="100%"
        />
        <IconButton
          aria-label="back"
          isDisabled={selectedSideIndex === 0}
          size="sm"
          onClick={() => setSelectedSideIndex(selectedSideIndex - 1)}
          position="absolute"
          top={`calc(50% - ${ARROW_SIZE / 2}px)`}
          left={0}
          variant="ghost"
        >
          <IconArrowRight transform="rotate(180deg)" h={`${ARROW_SIZE}px`} w={`${ARROW_SIZE}px`} />
        </IconButton>
        <IconButton
          aria-label="next"
          isDisabled={selectedSideIndex === size(template.sides) - 1}
          size="sm"
          onClick={() => setSelectedSideIndex(selectedSideIndex + 1)}
          position="absolute"
          top={`calc(50% - ${ARROW_SIZE / 2}px)`}
          right={0}
          variant="ghost"
        >
          <IconArrowRight h={`${ARROW_SIZE}px`} w={`${ARROW_SIZE}px`} />
        </IconButton>
      </Box>
      {template.sides.length > 1 ? (
        <HStack position="relative" bottom="40px" spacing="5px">
          {template.sides.map((side, index) => {
            const isHovered = side.id === hoveredSide;

            return (
              <Center
                as="button"
                borderRadius={4}
                borderColor="secondaryDarkGray.600"
                borderWidth={selectedSideIndex === index ? '1px' : 0}
                key={side.name}
                onClick={() => setSelectedSideIndex(index)}
                onMouseEnter={() => setHoveredSide(side.id)}
                onMouseLeave={() => setHoveredSide(null)}
                position="relative"
              >
                <SubmitDesignSideImage
                  design={design}
                  sideName={side.name}
                  imageWidth="62px"
                  height={THUMBNAIL_IMAGE_WIDTH}
                  width={THUMBNAIL_IMAGE_WIDTH}
                />
                {isHovered && !isMobile ? (
                  <Center
                    bg="rgba(98, 111, 130, 0.8)"
                    borderRadius={4}
                    position="absolute"
                    left={0}
                    top={0}
                    p="12px"
                    h="100%"
                    w="100%"
                  >
                    <Text as="b" color="#FFFFFF" textStyle="bodySmall">
                      {side.name}
                    </Text>
                  </Center>
                ) : null}
              </Center>
            );
          })}
        </HStack>
      ) : null}
    </Center>
  );
};

export default SubmitDesignPreviewGallery;
