import { getDesign, getDesignVotes, voteForDesign } from '@/api/designs';
import { useSizes } from '@/api/sizes';
import { useCategories } from '@/api/templates';
import Button from '@/components/button';
import AddToCartModal from '@/components/cart/AddToCartModal';
import IconCart from '@/components/icons/IconCart';
import IconChevronDownBreadCrumb from '@/components/icons/IconChevronBreadCrumb';
import IconShare from '@/components/icons/IconShare';
import VoteButton from '@/components/remix/VoteButton';
import { H3 } from '@/components/typography/Headings';

import { Design } from '@/lib';
import { getFormattedAmount } from '@/views/admin/utils/currency-formatter';

import DesignPreviewGallery from '@/views/demo/pages/design/components/DesignPreviewGallery';
import CreatorAvatar from '@/views/home/components/CreatorAvatar';
import {
  Box,
  Center,
  Flex,
  Hide,
  HStack,
  IconButton,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { capitalize, isEmpty } from 'lodash';

import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SizeChart } from './SizeChart';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import Sizes from './Sizes';
import MoreInfo from './MoreInfo';
import { createCartItem, updateCartItem } from '@/api/cart';
import { useQueryClient } from '@tanstack/react-query';
import { createMissingManufacturingImages } from '@/utils/design';
import { Template, User } from '@/components/types';
import RoyaltiesBanner from './RoyaltiesBanner';

const getShareLink = () => window.location.href;

const getShareContent = () => ({
  title: 'Check out this awesome merch I found on Ablo.ai',
  text: 'You can buy this merch on Ablo.ai #AIDesign #Ablo #AI #FashionAI',
  url: getShareLink(),
});

interface ProductDetailsProps {
  onSignInToAddToCart?: (selectedSizeId?: string) => void;
  onSignInToVote?: () => void;
  onSignInToViewIps?: () => void;
  me?: User;
}

const ProductDetails = ({
  onSignInToAddToCart,
  onSignInToVote,
  onSignInToViewIps,
  me,
}: ProductDetailsProps) => {
  const { id } = useParams<{ id: string }>();
  const [selectedSize, setSelectedSize] = useState(null);

  const [loading, setLoading] = useState(true);
  const [design, setDesign] = useState<Design>(null);

  const [isAddToCartModalVisible, setAddToCartModalVisible] = useState(false);

  const [hasVotedForDesign, setHasVotedForDesign] = useState(false);
  const [isVoting, setIsVoting] = useState(false);

  const [addingToCart, setAddingToCart] = useState(false);

  const { data: categories = [] } = useCategories();
  const { data: allSizes = [] } = useSizes();

  const history = useHistory();

  const toast = useToast();

  const { search } = useLocation();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!design) {
      return;
    }

    const queryParams = new URLSearchParams(search);

    const addingDesignToCart = queryParams.get('addingToCart');
    const selectedSize = queryParams.get('selectedSize');

    const votingForDesign = queryParams.get('votingForDesign');

    const selectedSizeAsNumber = parseInt(selectedSize);

    if (addingDesignToCart) {
      handleAddToCart(selectedSizeAsNumber);
    }

    if (selectedSize) {
      setSelectedSize(selectedSizeAsNumber);
    }

    if (votingForDesign) {
      handleVote();
    }
  }, [design]);

  useEffect(() => {
    const loadDesign = async () => {
      const design = await getDesign(id);

      try {
        const designVotes = await getDesignVotes();

        const votesForTemplate = designVotes.filter(
          (designVote) => designVote.templateId === design.template.id
        );

        setHasVotedForDesign(!isEmpty(votesForTemplate));
      } catch (e) {
        setHasVotedForDesign(false);
      }

      setSelectedSize(null);

      setDesign(design);

      setLoading(false);
    };

    loadDesign();
  }, [id]);

  const handleVote = () => {
    if (onSignInToVote) {
      onSignInToVote();

      return;
    }

    setIsVoting(true);

    voteForDesign(id)
      .then(() => {
        setHasVotedForDesign(true);
      })
      .catch((e) => {
        toast({
          title: e.message,
          status: 'error',
        });
      })
      .finally(() => {
        setIsVoting(false);
      });
  };

  if (loading) {
    return (
      <Center h="100vh" w="100%">
        <LoadingSpinner />
      </Center>
    );
  }

  const { sides, template, templateColorId, user } = design;
  const { categoryId, contestEndAt, colors, currency, gender, fit, name, price } =
    template as Template;

  const category = categories.find(({ id }) => id === categoryId);

  const variant = colors.find(({ id }) => id === templateColorId);

  let availableSizes = [];

  if (variant?.sizes?.length && allSizes?.length) {
    availableSizes = variant.sizes
      .map((tcs) => allSizes.find((s) => s.id === tcs.sizeId))
      .sort((s1, s2) => s1.id - s2.id);
  }

  const handleUpdateCart = (cart) => {
    queryClient.setQueryData(['me'], { ...me, cart });
  };

  const handleAddToCart = async (sizeId?: number) => {
    if (onSignInToAddToCart) {
      onSignInToAddToCart(selectedSize);

      return;
    }

    const sizeToUse = sizeId || selectedSize;

    if (!sizeToUse) {
      setAddToCartModalVisible(true);

      return;
    }

    const { cart } = me;

    setAddingToCart(true);

    const cartItem = cart.items.find(
      ({ templateColorSizeId }) => templateColorSizeId === sizeToUse
    );

    const size = variant.sizes.find(({ sizeId }) => sizeId === sizeToUse);

    try {
      if (!cartItem) {
        const params = {
          designId: design.id,
          templateColorSizeId: size.id,
          quantity: 1,
          storefrontId: size.storefrontVariantId,
        };

        await createMissingManufacturingImages(design);

        const newCartItem = await createCartItem(params);

        handleUpdateCart({
          ...cart,
          items: [...cart.items, newCartItem],
        });
      } else {
        const updatedCartItem = await updateCartItem(cartItem.id, cartItem.quantity + 1);

        handleUpdateCart({
          ...cart,
          items: cart.items.map((item) => {
            if (item.templateColorSizeId !== cartItem.templateColorSizeId) {
              return item;
            }

            return updatedCartItem;
          }),
        });
      }
    } catch (e) {
      console.error('E', e);

      toast({
        title: 'Error adding to cart',
        status: 'error',
      });
    } finally {
      setAddingToCart(false);
    }

    setAddToCartModalVisible(true);
  };

  const handleGoToIps = () => {
    if (onSignInToViewIps) {
      onSignInToViewIps();

      return;
    }

    history.push('/designs');
  };

  const handleGoBackToCategory = () => history.push(`/collections?categoryId=${categoryId}`);

  const handleGoBackToCollection = () => history.push(`/collection/${template.id}`);

  const canShare = navigator.share && navigator.canShare(getShareContent());

  const handleShare = async () => {
    const blob = await (await fetch(sides[0].previewImage)).blob();

    const file = new File([blob], `${name}.png`, { type: blob.type });

    try {
      await navigator.share({ ...getShareContent(), files: [file] });
    } catch (error) {
      toast({
        title: 'Error sharing content',
        description: error?.message,
        status: 'error',
      });
    }
  };

  const secondsToEnd = Math.floor((new Date(contestEndAt).getTime() - new Date().getTime()) / 1000);

  const hasVotingEnded = !contestEndAt || secondsToEnd <= 0;

  return (
    <Box bg="#FFFFFF" h="100%">
      <Box
        position="relative"
        h={{ base: 'calc(100dvh - 150px)', md: '100%' }}
        padding={{ base: '12px 16px 0 12px', md: '20px 32px 0px 32px' }}
        overflow="auto"
      >
        <HStack gap="4px" mb={{ base: '12px', md: '16px' }}>
          <Text color="brand.600" onClick={handleGoBackToCategory} textStyle="breadcrumb">
            {category?.name}
          </Text>
          <IconChevronDownBreadCrumb />
          <Text color="brand.600" textStyle="breadcrumb" onClick={handleGoBackToCollection}>
            {design.template.name}
          </Text>
          <IconChevronDownBreadCrumb />
          <Text color="secondaryDarkGray.600" fontWeight={400} textStyle="breadcrumb">
            {design.name}
          </Text>
        </HStack>
        <Stack
          alignItems="flex-start"
          direction={{ base: 'column', md: 'row' }}
          h={{ base: 'auto', md: 'calc(100% - 44px)' }}
          spacing={{ base: '12px', md: '50px' }}
        >
          <DesignPreviewGallery design={design} />
          <Box
            h={{ base: '100%', md: '536px' }}
            position="relative"
            w={{ base: '100%', md: 'auto' }}
            pb={{ base: 0, md: '90px' }}
          >
            <Box w={{ base: '100%', md: 'auto' }} h="100%" overflow={{ md: 'auto' }}>
              <RoyaltiesBanner onViewIps={handleGoToIps} />
              <Flex
                align="center"
                justify="space-between"
                mb={{ base: '8px', md: '10px' }}
                mt={{ base: '14px', md: '20px' }}
              >
                <H3>{design.name}</H3>

                {canShare ? (
                  <Hide above="md">
                    <IconButton
                      variant="secondary"
                      aria-label="Share"
                      icon={<IconShare />}
                      onClick={handleShare}
                    ></IconButton>
                  </Hide>
                ) : null}
              </Flex>
              <Text
                color="secondaryDarkGray.600"
                textStyle="bodySmall"
                mb={{ base: '12px', md: '16px' }}
              >
                {template.name}
              </Text>
              <Text mb={{ base: '12px', md: '16px' }} textStyle="button">
                {getFormattedAmount(currency.name, price as number)}
              </Text>
              <Text textStyle="bodySmall" mb={{ base: '14px', md: '16px' }}>
                {capitalize(gender)} / {fit}
              </Text>
              <Sizes
                sizes={availableSizes}
                onSelectedSize={setSelectedSize}
                selectedSize={selectedSize}
              />
              <CreatorAvatar fontSize={14} user={user} />
              <Box mb="24px" />
              <MoreInfo template={template} />
              <SizeChart templateId={template.id} />
              <HStack
                bg="#FFFFFF"
                bottom={{ base: '16px', md: '16px' }}
                left={{ base: '16px', md: 0 }}
                right={{ base: '16px', md: 0 }}
                position={{ base: 'fixed', md: 'absolute' }}
                spacing="12px"
              >
                <Button
                  isLoading={addingToCart}
                  onClick={() => handleAddToCart()}
                  w={{ base: '100%', md: 184 }}
                >
                  <HStack>
                    <IconCart />
                    <Text>Add to Cart</Text>
                  </HStack>
                </Button>
                <VoteButton
                  hasVotingEnded={hasVotingEnded}
                  hasVoted={hasVotedForDesign}
                  isLoading={isVoting}
                  onClick={handleVote}
                  p="0 16px"
                  w={{ base: '100%', md: 184 }}
                ></VoteButton>
              </HStack>
            </Box>
          </Box>
        </Stack>
        {isAddToCartModalVisible && (
          <AddToCartModal
            designId={design.id}
            goBackText="Back to product"
            onClose={() => setAddToCartModalVisible(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProductDetails;
