import { useEffect, useState } from 'react';

import {
  Box,
  Center,
  Flex,
  Heading,
  Hide,
  Show,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { getTemplates } from '@/api/templates';
import { DesignVote, Template } from '@/components/types';

import { Design, getTemplateWithPreviewImage } from '@/lib';
import Button from './components/ButtonCTA';

import RemixesList from '@/components/remix/RemixesList';

import RemixDetailsModal from './RemixDetailsModal';

import { useHistory, useLocation } from 'react-router-dom';
import { getDesigns, getDesignVotes, saveUserDesign, voteForDesign } from '@/api/designs';
import { groupBy } from 'lodash';
import FeaturesForUserTypes from './fragments/FeaturesForUserTypes';
import Brands from './fragments/Brands';
import CraftDesignsSection from './fragments/CraftDesignsSection';
import TemplatePreviewImage from './components/TemplatePreviewImage';

import IconMagic from '@/components/icons/IconMagic';
import RemixesSubmitted from './components/RemixesSubmitted';
import VoteToWinMerch from './fragments/VoteToWinMerch';
import KBWVideo from './components/KBWVideo';
import TemplateSelector from '@/lib/editor/template-selector';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import RoyaltiesAndBadgeModal from './royalties-and-badge-modal';
import { StorageKeys } from '@/constants';
import { H2, H5 } from '@/components/typography/Headings';

const getTopVotedDesign = async (template) => {
  const topDesigns = await getDesigns({
    isPublished: true,
    templateId: template.id,
    take: 1,
    skip: 0,
  });

  return topDesigns[0];
};

const getVotesForDesigns = (templateId: string, designVotes: DesignVote[]) => {
  const votesForTemplate = designVotes.filter((designVote) => designVote.templateId === templateId);

  return groupBy(votesForTemplate, 'designId');
};

interface HomeProps {
  onSignInToVote?: (designId: string) => void;
  onSignInToRemix?: () => void;
  onSignInToStartDesigning?: () => void;
}

export default function Home({
  onSignInToVote,
  onSignInToRemix,
  onSignInToStartDesigning,
}: HomeProps) {
  const [template, setTemplate] = useState<Template>(null);
  const [isLoading, setLoading] = useState(true);

  const [secondsLeftToContestEnd, setSecondsLeftToContestEnd] = useState(null);

  const [designId, setDesignId] = useState(null);

  const [waiting, setWaiting] = useState(false);

  const [designVotes, setDesignVotes] = useState({});
  const [votingForDesign, setVotingForDesign] = useState<string>(null);
  const [votedForDesign, setVotedForDesign] = useState<string>(null);

  const [isRoyaltiesAndBadgeModalVisible, setIsRoyaltiesAndBadgeModalVisible] = useState(false);
  const [isArtboardSelectorVisible, setArtboardSelectorVisible] = useState(false);

  const [topVotedDesign, setTopVotedDesign] = useState<Design>(null);

  const history = useHistory();

  const toast = useToast();

  const { search } = useLocation();

  const handleVote = (designToVoteForId: string) => {
    if (onSignInToVote) {
      onSignInToVote(designToVoteForId);

      return;
    }

    setVotingForDesign(designToVoteForId);

    voteForDesign(designToVoteForId)
      .then((newDesignVote) => {
        const votesForDesign = designVotes[designToVoteForId] || [];

        setDesignVotes({
          ...designVotes,
          [designToVoteForId]: [...votesForDesign, newDesignVote],
        });

        setVotedForDesign(designToVoteForId);

        setTimeout(() => {
          setVotedForDesign(null);
        }, 1000);

        if (!designId) {
          handleSelectDesign({ id: designToVoteForId });
        }
      })
      .catch((e) => {
        toast({
          title: e.message,
          status: 'error',
        });
      })
      .finally(() => {
        setVotingForDesign(null);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem(StorageKeys.HAS_SEEN_ROYALTIES_AND_BADGE_MODAL)) {
      setIsRoyaltiesAndBadgeModalVisible(true);

      localStorage.setItem(StorageKeys.HAS_SEEN_ROYALTIES_AND_BADGE_MODAL, 'true');
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    setDesignId(queryParams.get('designId'));
  }, [search]);

  useEffect(() => {
    let intervalId;

    const queryParams = new URLSearchParams(search);

    const votingForDesign = queryParams.get('votingForDesign');
    const remix = queryParams.get('remix');
    const startDesigning = queryParams.get('startDesigning');

    if (startDesigning) {
      handleStartDesigning();
    }

    Promise.all([getTemplates({ isFeatured: true }), getDesignVotes().catch(() => [])])
      .then(([templates, designVotes]) => {
        return Promise.all([
          getTemplateWithPreviewImage(templates[0]),
          getTopVotedDesign(templates[0]),
        ]).then(([featuredTemplate, topVotedDesign]) => {
          setTemplate(featuredTemplate);

          setTopVotedDesign(topVotedDesign);
          setDesignVotes(getVotesForDesigns(featuredTemplate.id, designVotes));

          const { contestEndAt } = featuredTemplate;

          const getSeconds = () =>
            Math.floor((new Date(contestEndAt).getTime() - new Date().getTime()) / 1000);

          const secondsToEnd = getSeconds();

          if (!contestEndAt || secondsToEnd <= 0) {
            setSecondsLeftToContestEnd(0);

            return;
          }

          setSecondsLeftToContestEnd(secondsToEnd);

          intervalId = setInterval(() => {
            const seconds = getSeconds();

            setSecondsLeftToContestEnd(Math.max(seconds, 0));

            if (seconds < 0) {
              clearInterval(intervalId);
            }
          }, 1000);

          if (votingForDesign) {
            handleVote(votingForDesign);
          } else if (remix) {
            handleRemix(featuredTemplate);
          }
        });
      })
      .finally(() => {
        if (!votingForDesign) {
          setLoading(false);
        }
      });

    return () => clearInterval(intervalId);
  }, []);

  const handleCreatedDesign = (newDesign) => {
    history.push(`/designs/${newDesign.id}`);

    setArtboardSelectorVisible(false);
  };

  const handleSelectDesign = (design: Design | { id: string }) => {
    history.replace(`?designId=${design.id}`);
  };

  const handleGoToCollection = () => {
    history.push(`collection/${template.id}`);
  };

  const handleStartDesigning = () => {
    if (onSignInToStartDesigning) {
      onSignInToStartDesigning();

      return;
    }

    setArtboardSelectorVisible(true);
  };

  const handleRemix = async (featuredTemplate?) => {
    if (onSignInToRemix) {
      onSignInToRemix();

      return;
    }

    const templateToUse = featuredTemplate || template;

    const { colors, id } = templateToUse;

    const sides = templateToUse.sides.map((side) => ({
      templateSideId: side.id,
    }));

    const params = {
      sides,
      template,
      templateId: id,
      templateColorId: colors[0].id,
    };

    setWaiting(true);

    try {
      const newDesign = await saveUserDesign(params as Design);

      history.push(`/designs/${newDesign.id}`);
    } catch (e) {
      toast({
        title: 'Error creating a remix',
        status: 'error',
      });
    } finally {
      setWaiting(false);
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Center bg="transparent" h="calc(100vh - 100px)">
        <LoadingSpinner />
      </Center>
    );
  }

  const handleCloseRoyaltiesAndBadgeModal = () => {
    setIsRoyaltiesAndBadgeModalVisible(false);

    localStorage.setItem(StorageKeys.HAS_SEEN_ROYALTIES_AND_BADGE_MODAL, 'true');
  };

  const votingEnded = secondsLeftToContestEnd === 0;

  return (
    <Center bg="#FFFFFF" h="100%" w="100%">
      <Box
        h="100%"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none', // IE and Edge
          'scrollbar-width': 'none', // Firefox
        }}
        pb={{ base: '80px', lg: 0 }}
        pt={{ base: '36px', '2sm': '40px', lg: '90px' }}
        px={{ base: 0, '2sm': '32px' }}
        w={{ base: '100%', xl: '1170px' }}
      >
        <Stack
          align={{ base: 'center', '2sm': 'flex-start', lg: 'center' }}
          justify={{ base: 'center', lg: 'space-between' }}
          direction={{ base: 'column', lg: 'row' }}
          mb={{ base: '42px', '2sm': '72px' }}
          px={{ base: '16px', '2sm': 0 }}
          spacing={{ base: '8px', xl: '24px' }}
          w="100%"
        >
          <Box flex={1}>
            <Heading
              fontSize={{ base: '30px', md: '46px' }}
              maxW={{ base: '260px', md: '304px' }}
              mb={{ base: '32px', lg: 0 }}
            >
              Create. Collaborate. Earn.
            </Heading>
            <Hide above="xl">
              <KBWVideo width="100%" />
            </Hide>
            <Text
              color="secondaryDarkGray.600"
              mt={{ base: '32px', '2sm': '18px' }}
              mb={{ base: '24px', '2sm': '32px' }}
              w={{ base: '340px', '2sm': '100%' }}
              textStyle="body"
            >
              Design with AI, partner with leading brands, and monetize your creativity on Web3.
            </Text>
            <Stack direction={{ base: 'column', '2sm': 'row' }} w={{ base: '100%', lg: '416px' }}>
              <Button flex={{ base: 'auto', md: 1 }} onClick={handleStartDesigning}>
                Start Designing
              </Button>
              <Button
                flex={{ base: 'auto', md: 1 }}
                onClick={() => handleGoToCollection()}
                secondary
              >
                Shop Now
              </Button>
            </Stack>
          </Box>
          <Show above="xl">
            <KBWVideo height="391px" />
          </Show>
          <Hide above="md">
            <Box />
          </Hide>
        </Stack>
        <FeaturesForUserTypes onRemix={handleStartDesigning} />
        <Show above="lg">
          <Flex justify="center" p="44px 0 52px 0">
            <Button onClick={handleStartDesigning} w="200px">
              Start Designing
            </Button>
          </Flex>
        </Show>
        <Box mb={{ base: '12px', '2sm': '64px', lg: 0 }} />
        <Brands />
        <CraftDesignsSection onAction={handleStartDesigning} />
        {template && (
          <Hide below="2sm">
            <Hide above="lg">
              <H2 alignSelf="center" textAlign="center" m="64px auto 32px auto" w="501px">
                Remix the latest collection {template.brand}
              </H2>
            </Hide>
          </Hide>
        )}
        {template && (
          <Stack
            direction={{ base: 'column', '2sm': 'row' }}
            justify="center"
            mt={{ base: '24px', lg: '64px' }}
            spacing={{ base: 0, '2sm': '32px', lg: '50px' }}
          >
            <Show above="2sm">
              <TemplatePreviewImage topDesign={topVotedDesign} />
            </Show>
            <VStack
              align={{ base: 'center', '2sm': 'flex-start' }}
              mb={{ base: '32px', '2sm': '60px', lg: '16px' }}
              p={{ base: 0, lg: '17px 0' }}
              maxW={{ base: 'none', lg: '420px' }}
              spacing={{ base: '12px', lg: 0 }}
              w="100%"
            >
              <Show above="lg">
                <H2
                  alignSelf="flex-start"
                  textAlign="left"
                  ml={{ base: '16px', md: 0 }}
                  mb={{ base: 0, lg: '24px' }}
                  w={{ base: '341px', lg: 'auto' }}
                >
                  Remix the latest collection {template.brand}
                </H2>
              </Show>
              <Hide above="2sm">
                <TemplatePreviewImage topDesign={topVotedDesign} />
              </Hide>
              <VStack align="flex-start" p={{ base: '5px 16px 0 12px', lg: 0 }} w="100%">
                <H5 fontWeight={700} mb={{ base: 0, md: '6px' }}>
                  {template.name}
                </H5>
                <Text color="secondaryDarkGray.600" textStyle="body">
                  {template.collectionDescription}
                </Text>
                <Hide above="lg">
                  <RemixesSubmitted numRemixes={template.numRemixes} />
                </Hide>
                <Stack
                  direction={{ base: 'column', lg: 'row' }}
                  mt={{ base: '5px', lg: '12px' }}
                  spacing="12px"
                  w="100%"
                >
                  <Button
                    onClick={() => handleRemix()}
                    isLoading={waiting}
                    w={{ base: '100%', lg: '204px' }}
                  >
                    <IconMagic mr="6px" />
                    Remix
                  </Button>
                  <Button
                    secondary
                    onClick={handleGoToCollection}
                    w={{ base: '100%', lg: '204px' }}
                  >
                    <Text>Explore Collection</Text>
                  </Button>
                </Stack>
                <Show above="lg">
                  <RemixesSubmitted numRemixes={template.numRemixes} />
                  <Box mb="2px" />
                </Show>
              </VStack>
            </VStack>
          </Stack>
        )}
        <VoteToWinMerch />
        <VStack
          m={{ base: '24px 0 40px 0', md: '60px 0' }}
          p={{ base: '0 17px', '2sm': '0 0 500px 0', lg: '0 0 100px 0' }}
        >
          <RemixesList
            designVotes={designVotes}
            maxItems={4}
            template={template}
            onVote={setDesignId}
            votingForDesign={votingForDesign}
            votedForDesign={votedForDesign}
          />
          <Button
            mt={{ base: '20px', md: '33px' }}
            secondary
            onClick={handleGoToCollection}
            w={{ base: '100%', md: '204px' }}
          >
            <Text>Explore More</Text>
          </Button>
        </VStack>
        {designId ? (
          <RemixDetailsModal
            designId={designId}
            designVotes={designVotes}
            hasVotingEnded={votingEnded}
            isVoting={votingForDesign === designId}
            onClose={() => setDesignId(null)}
            onVote={handleVote}
            votedForDesign={votedForDesign}
          />
        ) : null}
        {isArtboardSelectorVisible ? (
          <TemplateSelector
            onClose={() => setArtboardSelectorVisible(false)}
            onCreatedDesign={handleCreatedDesign}
          />
        ) : null}
        {isRoyaltiesAndBadgeModalVisible ? (
          <RoyaltiesAndBadgeModal
            onClose={handleCloseRoyaltiesAndBadgeModal}
            onSelectTemplate={() => {
              handleCloseRoyaltiesAndBadgeModal();

              handleStartDesigning();
            }}
          />
        ) : null}
      </Box>
    </Center>
  );
}
