import { Box, Center, Text, useToast } from '@chakra-ui/react';

import { Design } from '@/lib/types';

import { deleteDesign, updateBaseDesign } from '@/api/designs';

import { timeAgo } from '@/utils/time';

import CardMenu from '../components/CardMenu';
import { useHistory } from 'react-router-dom';

import RenameDesignModal from './RenameDesignModal';
import { useEffect, useState } from 'react';
import { getDesignPreviewImage } from '@/lib';
import { downloadFromUrl } from '@/lib/utils/download';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import DesignCardContainer from '@/components/remix/DesignCardContainer';
import { getFormattedAmount } from '../utils/currency-formatter';
import { useCurrencies } from '@/api/currencies';
import Button from '@/components/button';
import IconEdit from '@/components/icons/IconEdit';

type DesignCardProps = {
  design: Design;
  onDesignDeleted: (id: string) => void;
  onDesignUpdated: (updates: Partial<Design>) => void;
};

const DesignCard = ({ design, onDesignDeleted, onDesignUpdated }: DesignCardProps) => {
  const [renamingDesign, setRenamingDesign] = useState<Design>(null);
  const [isUpdating, setUpdating] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isDownloading, setDownloading] = useState(false);

  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(true);
  const [designPreviewImage, setDesignPreviewImage] = useState<string>(null);

  const history = useHistory();

  const toast = useToast();

  const { data: currencies = [] } = useCurrencies();

  const currency = currencies.find(({ id }) => id === design?.template?.currencyId);

  useEffect(() => {
    getDesignPreviewImage(design).then(({ designImage }) => {
      setDesignPreviewImage(designImage);

      setIsLoadingPreviewImage(false);
    });
  }, []);

  const { id, name } = design;

  const handleRename = (name) => {
    setUpdating(true);

    const updates = { id: renamingDesign.id, name };

    updateBaseDesign(updates).then(() => {
      setUpdating(false);

      setRenamingDesign(null);

      onDesignUpdated(updates);
    });
  };

  const handleDelete = () => {
    setDeleting(true);

    deleteDesign(id)
      .then(() => {
        onDesignDeleted(id);

        toast({
          title: 'Design successfully deleted',
          status: 'success',
        });
      })
      .catch((e) => {
        setDeleting(false);

        toast({
          title: 'Error deleting design',
          description: e.message,
          status: 'error',
        });
      });
  };

  const handleEdit = () => history.push(`/designs/${design.id}`);

  const handleDownload = async () => {
    setDownloading(true);

    try {
      await downloadFromUrl(designPreviewImage, `${design.sides[0].templateSide?.name}`);
    } catch (e) {
      toast({
        title: 'Error downloading',
        status: 'error',
      });
    } finally {
      setDownloading(false);
    }
  };

  const handleGoToProductDetails = () => history.push(`/products/${design.id}`);

  const { isPublished, numPurchases, template } = design;

  return (
    <Box position="relative" w="100%">
      <DesignCardContainer
        design={design}
        height={isPublished ? { base: 290, lg: 320 } : { base: 274, lg: 302 }}
      >
        {isDeleting || isDownloading ? (
          <Center
            position="absolute"
            w="100%"
            h="100%"
            bg={isLoadingPreviewImage ? 'transparent' : 'black'}
            opacity="0.5"
            left={0}
            top={0}
          >
            <LoadingSpinner />
          </Center>
        ) : null}
        <>
          <Text
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            maxW="100%"
            textStyle="button"
          >
            {name}
          </Text>
          {isPublished ? (
            <>
              <Text textStyle="bodySuperSmall">Sold: {numPurchases}</Text>
              <Text textStyle="bodySuperSmall" mb="14px">
                Earned:{' '}
                {getFormattedAmount(currency?.name, numPurchases * (template?.price as number))}
              </Text>
            </>
          ) : (
            <Text textStyle="bodySuperSmall" mb="14px">
              Edited {timeAgo(design.updatedAt)}
            </Text>
          )}

          {isPublished ? (
            <Button onClick={handleGoToProductDetails} small w="100%">
              View on Shop
            </Button>
          ) : (
            <Button onClick={handleEdit} secondary small w="100%">
              <IconEdit mr="6px" />
              Edit
            </Button>
          )}
        </>
      </DesignCardContainer>
      <Box position="absolute" right="0" top="8px">
        <CardMenu
          isDownloadDisabled={isLoadingPreviewImage}
          onDelete={!isPublished && handleDelete}
          onDownload={designPreviewImage && handleDownload}
          onRename={isPublished ? null : () => setRenamingDesign(design)}
        />
      </Box>
      {renamingDesign && (
        <RenameDesignModal
          designName={renamingDesign.name}
          isRenaming={isUpdating}
          onClose={() => setRenamingDesign(null)}
          onSave={handleRename}
        />
      )}
    </Box>
  );
};

export default DesignCard;
